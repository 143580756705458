<template>
  <div>
    <v-card width="100%" class="text-center" color="#fff">
      <v-img contain max-height="300px" src="/educaaws.jpeg"></v-img>
      <v-btn
        :to="{ name: 'VideoForm' }"
        v-if="me.role.name === 'admin'"
        color="primary"
        class="mb-4"
      >
        Cadastrar novo video
      </v-btn>
    </v-card>

    <v-container class="lighten-3" fluid text-center>
      <v-row class="mt-4">
        <v-col v-for="video in dataTable" :key="video.id" cols="12" md="6">
          <v-card style="height: 100%;" :color="'#014486'" dark>
            <div class="d-flex flex-no-wrap justify-space-between text-center">
              <div style="text-align: center;">
                <v-card-title
                  class="text-h6 mx-auto text-left text-justify text-break "
                  style="overflow-wrap: break-word; width: 100%; font-size: 1rem !important;"
                  v-text="video.name"
                ></v-card-title>

                <v-card-subtitle
                  class="text-left text-start"
                  v-text="'Autor: Wellington Sampaio'"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    :to="{ path: '/app/academy/' + video.id }"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                    Assistir
                  </v-btn>
                  <v-btn
                    :to="{ name: 'VideoEditForm', params: { id: video.id } }"
                    v-if="me.role.name === 'admin'"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                    Editar
                  </v-btn>
                  <v-btn
                    @click="() => deleteVideo(video.id)"
                    v-if="me.role.name === 'admin'"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                    Excluir
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img :src="video.url"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";
import moment from "moment";
import instanceAxios from "../../plugins/axios";
import { mapGetters } from "vuex";
export default {
  created: async function() {
    await this.indexData();
  },

  props: {
    private: {
      default: false,
      type: Boolean,
      required: false,
    },
    curso: Number,
  },

  components: {},
  computed: {
    countProposta: {
      get() {
        var a = this.dataTable.fechado.length;
        var b = this.dataTable.contato.length;
        var c = this.dataTable.enviado.length;
        return a + b + c;
      },
    },
    ...mapGetters({
      me: "getMe",
    }),
  },
  data: () => ({
    ComercialService: new ComercialService(""),
    loading: false,
    query: "",
    dataTable: [],
    headers: [
      { title: "OPORTUNITY", nick: "Oportunidade" },
      { title: "NAME", nick: "Nome" },
      { title: "POST", nick: "Cargo" },
      { title: "EMAIL", nick: "Email" },
      { title: "PHONE", nick: "Telefone" },
      { title: "RETURN", nick: "Retorno" },
    ],
  }),

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY");
    },
  },

  methods: {
    async indexData() {
      try {
        this.loading = true;
        const url = this.private ? "?private=true" : "";
        const res = await instanceAxios.get(`cursos/${this.curso ?? 0}` + url);
        this.dataTable = res.data.videos;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.loading = false;
      }
    },
    countPropostas() {
      var a = this.dataTable.contato.length;
      var b = this.dataTable.enviado.length;
      var c = this.dataTable.fechado.length;
      return a + b + c;
    },

    async deleteVideo(id) {
      try {
        await instanceAxios.delete(`videos/${id}`);

        ToastService("Deletado com sucesso", "success");

        this.indexData();
      } catch (error) {
        ToastService("Não foi possível excluir", "error");
      }
    },
  },
};
</script>

<style></style>
